.justify-text-content {
  text-align: justify !important;
}

.new-store-margin-top {
  margin-top: 120px;
}

.new-store-position-relative {
  position: relative;
}

.main-new-store {
  background-color: rgb(255, 255, 255);
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.new-store-container {
  padding: 16px 30px;
  border: 2px solid rgb(108, 140, 127);
  border-radius: 3px;
  text-align: center;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 8px 0px;
}

.new-store-title {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  border: 1px solid #ccc;
  padding: 10px;
  display: none;
}

.new-store-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 30px;
}

.new-store-button {
  all: unset;
  cursor: pointer;
  background-color: rgb(46, 77, 65);
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  transition: all 0.3s linear;
  min-width: 90px;
  font-size: 13px;
}
.new-store-button-del {
  all: unset;
  cursor: pointer;
  background-color: rgb(217, 36, 72);
  color: white;
  padding: 4px 5px;
  border-radius: 4px;
  transition: all 0.3s linear;
  min-width: 90px;
}
.new-store-delete-btn {
  background-color: red;
}

.new-store-bold {
  font-size: 16px;
  font-weight: 600;
}

.new-store-url {
  font-size: 14px;
  color: blue;
  font-weight: 500;
  text-align: left;
}

.new-store-content-column {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 30px;
}

.new-store-content-row {
  display: flex;
  align-items: center;
  gap: 30px;
  border-bottom: 1px dashed rgb(204, 200, 200);
  padding-bottom: 20px;
}
.new-store-content-button {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px dashed rgb(204, 200, 200);
  padding-bottom: 20px;
}
.new-store-content-button:last-child {
  border-bottom: none;
  padding-bottom: 0px;
}

.new-store-content-row:last-child {
  border-bottom: none;
  padding-bottom: 0px;
}

.new-store-side {
  white-space: nowrap;
}

.new-store-middle {
  width: 60%;
  margin: 0 auto;
}

.new-store-add-domain-container {
  border: 1px solid rgb(204, 200, 200);
  border-radius: 10px;
  text-align: center;
  overflow: hidden;
  box-shadow: rgba(5, 5, 255, 0.2) 0px 7px 29px 0px;
}

.new-store-add-domain-content-column {
  padding: 16px 60px;
}

.new-store-add-domain-title {
  text-align: center;
  font-weight: 600;
  color: white;
  position: sticky;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  cursor: pointer;
}

.new-store-add-domain-btn-content {
  background-color: rgba(185, 185, 185, 0.23);
  padding: 12px 60px;
}

.new-store-add-domain-message {
  height: auto;
  background-color: rgba(102, 242, 134, 0.23);
  padding: 16px 60px;
  text-align: left;
  font-weight: 600;
  color: green;
}

.new-store-add-domain-error {
  background-color: rgba(235, 102, 102, 0.23);
  padding: 16px 60px;
  text-align: left;
  font-weight: 600;
  color: red;
}

.new-store-add-domain-btn {
  all: unset;
  cursor: pointer;
  padding: 6px 12px;
  background-color: rgb(62, 93, 151);
  color: white;
  border-radius: 3px;
}

/* modal desing */
.new-store-modal .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  min-width: 450px !important;
}

.new-store-modal .css-hlj6pa-MuiDialogActions-root {
  padding: 16px 24px !important;
}

.new-store-modal input {
  width: 90%;
  border: none;
  outline: none;
  padding: 12px;
  border: 1px solid rgb(208, 207, 207);
  border-radius: 10px;
}

.new-store-no-domain-available {
  padding: 16px 0px;
  background: aliceblue;
  margin-top: 10px;
  border: 2px solid #ccc;
  line-height: 30px;
}

.new-store-disabled-btn {
  cursor: not-allowed;
  opacity: 0.6;
}

/* Store creation start */
.store-creation-main {
  width: 98%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #daffef;
  margin: 10px auto;
  border-radius: 3px;
  border: 2px solid #00000024;
}
.store-creation-main p {
  text-align: center;
  background: white;
  padding: 12px;
  border-radius: 10px;
  border: 2px solid #ccccccb8;
  font-size: 26px;
  width: 90%;
  line-height: 40px;
}
.store-input-submit {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  background: white;
  padding: 12px;
  border-radius: 10px;
  border: 2px solid #ccccccb8;
  margin-bottom: 30px;
}
.store-input-submit input {
  display: block;
  width: 30%;
  border-radius: 5px;
  background: rgba(109, 124, 144, 0.1);
  margin: 20px 0;
  padding: 8px 20px;
  text-align: left;
  border: 2px solid #ccc;
  transition: 300ms all cubic-bezier(0.4, 0, 0.2, 1);
  line-height: 1.8em;
  letter-spacing: 0.5px;
  font-weight: 700;
  color: #20292f;
  outline: none;
}
.store-input-submit button {
  border: 0;
  width: 20%;
  outline: 0;
  border-radius: 11px;
  padding: 10px 0;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  background: linear-gradient(120deg, rgba(10, 84, 247, 0.77) 0%, #4d86ff 100%);
  cursor: pointer;
  margin-left: 20px;
  font-size: 19px;
}
/* Store creation end */

@media all and (max-width: 768px) {
  /* Store creation start */
  .store-creation-main {
    width: 98%;
  }
  .store-creation-main p {
    font-size: 18px;
    line-height: 31px;
  }
  .store-input-submit {
    flex-direction: column;
    margin-bottom: 22px;
  }
  .store-input-submit input {
    width: 85%;
  }
  .store-input-submit button {
    width: 100%;
    margin: 5px 0px 18px 0px;
    border-radius: 4px;
  }

  /* Store creation end */
  .new-store-margin-top {
    margin-top: 180px;
  }

  .new-store-position-relative {
    position: none;
  }

  .main-new-store {
    padding: 5px;
  }

  .new-store-container {
    padding: 16px 10px;
  }

  .new-store-row {
    flex-direction: column;
    gap: 30px;
  }

  .new-store-content-row {
    flex-direction: column;
    gap: 20px;
  }

  .new-store-content-column {
    padding: 0 10px;
  }

  .new-store-middle {
    width: 100%;
  }

  .new-store-title {
    display: none;
  }

  .new-store-add-domain-error,
  .new-store-add-domain-message {
    padding: 10px;
  }

  .new-store-add-domain-content-column {
    padding: 16px;
  }

  /* modal desing */
  .new-store-modal .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    min-width: 95% !important;
  }
  .new-store-url {
    text-align: center;
  }
  .new-store-no-domain-available {
    padding: 15px;
    text-align: center;
  }
}
