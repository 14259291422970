.ManualOrder{
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 11%;
    position: fixed;
    top: 12%;
    right: 0px;
    background-color: #70c332;
    z-index: 9999999;
    border-radius: 7px 0px 0px 7px;
    overflow: hidden;
    color: #fff;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    cursor: pointer;
    padding: 7px;
    text-transform: uppercase;
    font-size: 17px;
}
.ManualOrder:hover{
border: 1px solid #ccc;    
}