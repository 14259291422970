@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");

.landing-page-main-container,
.landing-page-main-container *,
.landing-page-main-container *::after,
.landing-page-main-container *::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html,
body {
  font-family: "Roboto", sans-serif;
}

.bold__text {
  font-weight: bold;
}

.no__border__bottom {
  border: none !important;
}

.container {
  max-width: 1040px;
  margin: 0 auto;
  padding: 0px 10px;
}

.header__container2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px 20px;
}

.header__container img {
  cursor: pointer;
}

.video__container {
  width: 100%;
  aspect-ratio: 16 / 9;
  text-align: center;
  padding: 31px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.video__container button {
  all: unset;
  width: fit-content;
  cursor: pointer;
  font-size: 24px;
  font-weight: 600;
  background-color: #008037;
  color: white;
  border-style: solid;
  border-width: 3px 3px 3px 3px;
  border-color: #ffffff;
  border-radius: 63px 63px 63px 63px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  padding: 14px 30px 14px 30px;
}

.landing1faq {
  background-color: #008037;
  color: white;
}

.landing1faq__container {
  padding: 20px 0px;
}

.landing1faq__title {
  width: 100%;
  text-align: center;
  margin-bottom: 30px;
}

.pricing__container {
  padding: 30px 0px;
  text-align: center;
}

.pricing__container__title {
  padding-bottom: 20px;
}

.pricing__cards__container {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.pricing__card__container {
  flex: 1;
  height: 280px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.63);
  margin: 5px;
  padding: 50px 20px;
  color: #008037;
}

.pricing__card__container h1 {
  color: #000;
}

.review__container {
  padding: 30px 0px;
  text-align: center;
}

.review__container img {
  width: 100%;
}

.review__title h2 {
  color: #008037;
  padding-bottom: 30px;
}

.footer {
  background-color: #000;
}

.footer__container {
  padding: 30px 0px;
  text-align: left;
  color: #fff;
}

.form__container {
  margin: 50px 0px;
  padding: 40px 30px;
  border: 1px solid black;
  border-radius: 10px;
}

.form__container h2 {
  text-align: center;
  padding-bottom: 30px;
}

.form__container__group {
  display: flex;
  align-items: flex-start;
  gap: 30px;
}

.form__col {
  width: 50%;
}

.form__row p {
  padding-bottom: 20px;
  font-size: 1.3rem;
  color: #404040;
}

.form__input__group {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 16px;
}

.form__input__group input {
  border: 0;
  outline: 0;
  padding: 10px;
  border: 1px solid gray;
  border-radius: 5px;
}

.form__input__group input:focus {
  border-color: blue;
}

/* .form__input__group input:invalid {
  border-color: red;
} */

.form__package__container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.package__card__container {
  padding: 20px 30px;
  border: 1px solid gray;
  border-radius: 10px;
  display: flex;
  align-items: flex-start;
  gap: 20px;
  cursor: pointer;
  box-shadow: 0 5px 10px -5px rgba(150, 150, 150, 0.5);
}

.package__card__content p {
  font-size: 1rem;
}

.form__row button {
  all: unset;
  width: 100%;
  background-color: #133196;
  color: white;
  cursor: pointer;
  padding: 16px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 5px;
}

.form__note__content {
  width: 100%;
  background-color: #f7f7f7;
  padding: 16px 10px;
  margin-bottom: 10px;
  font-weight: 600;
}

.form__note__content .form__note {
  background-color: #eaeaea;
  padding: 16px 10px;
  font-size: 0.9rem;
  margin-top: 10px;
  font-weight: normal;
}

.form__privacy__text {
  font-size: 12px;
  color: #777;
  margin-bottom: 16px;
}

.form__contact__number {
  color: green;
  margin-top: 30px;
}

/**
 accordion style
 */
.accordion {
  display: flex;
  flex-direction: column;
}

.accordion-item {
  border-bottom: 1px solid #ccc;
}

.accordion-item:last-child {
  border-bottom: 0;
}

.accordion-header {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.icon {
  margin-right: 10px;
}

.accordion-content {
  height: 0;
  display: hidden;
  opacity: 0;
  transition: all 150ms linear;
  overflow: hidden;
}

.accordion-content.open {
  height: fit-content;
  padding: 20px 10px;
  opacity: 1;
}

.accordion-content.close {
  height: 0;
  padding: 0;
  opacity: 0;
}

/*
cart style
**/

.cart {
  margin-bottom: 20px;
}

.cart__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0px;
  border-bottom: 1px dashed gray;
  gap: 20px;
}

.cart__container__right {
  max-width: 120px;
  text-align: left;
}

.cart__container__left {
  display: flex;
  align-items: center;
  flex: 1;
}

.cart__container__left__content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 30px;
}

.cart__container__image {
  width: 40px;
  height: 40px;
}

.cart__container__image img {
  width: 100%;
  height: auto;
}

.cart__shipping__methods {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@media all and (max-width: 768px) {
  .container {
    width: 100%;
  }

  .form__container {
    padding: 40px 10px;
  }

  .video__container {
    aspect-ratio: 4/3;
  }

  .pricing__cards__container {
    flex-direction: column;
  }

  .pricing__card__container {
    width: 100%;
  }

  .review__title img {
    width: 100%;
    height: auto;
  }

  .footer__container {
    justify-content: center;
    text-align: center;
  }

  .form__container__group {
    flex-direction: column;
  }

  .form__col {
    width: 100%;
    flex-direction: column;
  }

  .form__row button {
    width: fit-content;
    padding: 16px;
  }
}
