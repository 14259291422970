.cart {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 128px;
  }
  .cart .card {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .cart .card img {
    width: 40px;
    height: 40px;
  }
  .cart .card p {
    width: 200px;
  }
  .cart .card .quantity {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .cart .card .quantity p {
    width: 50px;
    text-align: center;
  }
  .cart .card .quantity button {
    width: 50px;
  }
  .cart .total {
    text-align: right;
    border-top: 1px solid black;
  }

  .checkout-button{
    padding: 17px;
    background-color: #70c332;
    cursor: pointer;
  }

.checkout-button:hover{
    background-color: #fff;
    color: #450012;
    border-color: #450012;
}
  