.team-main {
  min-height: 90vh;
  width: 96%;
  margin: 7px auto;
}

.team-video {
  background-color: #d9e7e3;
  width: 94.8%;
  display: flex;
  align-items: center;
  padding: 7px;
  justify-content: center;
  margin: 10px auto;
  border-radius: 3px;
  font-size: 18px;
  border: 2px solid #ccc;
}

.team-actions {
  align-items: center;
  background: linear-gradient(90deg, #555ea4, #555ea4);
  display: flex;
  justify-content: center;
  padding: 15px;
  border-radius: 2px;
}

.team-no-data {
  width: 100%;
  padding: 12px;
  text-align: center;
  font-weight: 500;
}

@media (max-width: 880px) {
  .team-main {
    width: 97%;
  }
  .team-video {
    width: 91%;
    font-size: 15px;
  }
}
