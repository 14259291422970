.add-product {
  display: flex;
  justify-content: center;
  align-items: center;
  background: antiquewhite;
}
.rdw-dropdownoption-default {
  color: #000;
}
.rdw-dropdown-selectedtext {
  color: #000;
}
.rdw-link-modal-label {
  color: #000;
}
.rdw-justify-aligned-block {
  color: #000;
}
.public-DraftStyleDefault-ltr {
  color: #000;
}
.rdw-image-modal-header {
  color: #000;
}
.rdw-image-modal-upload-option {
  overflow: hidden;
}

.add-sub {
  display: flex;
  flex-direction: column;
  height: 40%;
  width: 50%;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  padding: 20px;
  margin-top: 40px;
  text-align: center;
  color: #fff;
  opacity: 75%;
  background-color: #ff9351;
}
.add-sub h3 {
  margin-bottom: 20px;
}

.sub-button {
  display: inline-block;
  outline: 0;
  cursor: pointer;
  padding: 9px 1px;
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  vertical-align: middle;
  border: 1px solid;
  border-radius: 6px;
  color: #ffffff;
  background-color: #2ea44f;
  border-color: #1b1f2326;
  box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px 0px,
    rgba(255, 255, 255, 0.25) 0px 1px 0px 0px inset;
  transition: 0.2s cubic-bezier(0.3, 0, 0.5, 1);
  transition-property: color, background-color, border-color;
  margin-top: 15px;
}
.sub-button:hover {
  background-color: #2c974b;
  border-color: #1b1f2326;
  transition-duration: 0.1s;
}
.form__label {
  font-family: "Roboto", sans-serif;
  font-size: 1.2rem;
  margin-left: 2rem;
  margin-top: 0.7rem;
  display: block;
  transition: all 0.3s;
  transform: translateY(0rem);
}

.form__input {
  font-family: "Roboto", sans-serif;
  color: #333;
  font-size: 1.2rem;
  margin: 0 auto;
  padding: 0.5rem 1rem;
  border-radius: 0.2rem;
  background-color: rgb(255, 255, 255);
  border: none;
  width: 93%;
  display: block;
  border-bottom: 0.3rem solid transparent;
  transition: all 0.3s;
  margin-bottom: 8px;
}

.form__input:placeholder-shown + .form__label {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(-4rem);
  transform: translateY(-4rem);
}
.variation-addproduct {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.variation-input {
  width: 61%;
  padding: 12px;
  border-radius: 4px;
  border: none;
  margin-bottom: 8px;
}
.variation-button {
  width: 30%;
  padding: 9px;
  border-radius: 4px;
  border: none;
  margin-bottom: 8px;
  margin-left: 3px;
  background-color: green;
  color: #fff;
  font-size: 19px;
}
.display-variation {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.about-variation {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
}
.about-variation-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.display-variation-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 2px dotted;
  margin: 5px;
  overflow: hidden;
  padding: 0px 0px 0px 0px;
  transition: 0.7s;
}
.va-inpute {
  width: 12%;
  padding: 3px;
  transition: 0.7s;
}
.va-item-p {
  font-size: 12px;
  background-color: green;
  padding: 4px;
  border-radius: 13px;
  transition: 0.7s;
  width: 11%;
}
.myFile-var {
  width: 16%;
}
.var-item-remove {
  width: 5%;
  height: 5%;
  background-color: #f00;
  border-color: #f00;
}

.ColorSizeMain {
  width: 93%;
  border: 2px dotted #ccc;
  padding: 7px;
  margin: 0 auto;
  position: relative;
}
.remove-color-size {
  position: absolute;
  top: 6px;
  right: 7px;
  background-color: #f00;
  border-color: #f00;
  height: 20px;
  width: 20px;
}
.SizeName-input {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.color-Size-Title {
  background-color: #2c974b;
  margin-bottom: 5px;
}
.color-size-name {
  width: 9%;
  text-align: left;
}
.size-inpute {
  width: 14%;
}
.myFile-ColorSize {
  width: 15%;
}
.myFile {
  padding-left: 85px;
  padding-top: 10px;
}
.category-addproduct {
  padding: 11px;
  margin: 10px;
}
.upload-image {
  display: flex;
  align-items: center;
  justify-content: center;
}
