/* AddBanner.css */
.add-banner-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 1080px;
  margin: 40px auto;
}

.add-banner-btn-groups {
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding: 20px;
}

.image-div {
  width: 100%;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.image-div img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.image-container {
  text-align: center;
  width: 100%;
  height: 100%;
}

.upload-container {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px;
}

.upload-container input[type="text"] {
  width: 350px;
  border: 0;
  outline: 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
}

.add-banner-update-btn {
  background-color: green !important;
}

.add-banner-close-btn {
  position: absolute;
  right: 20px;
  font-size: 28px;
  cursor: pointer;
  color: #666;
}

@media all and (max-width: 768px) {
  .add-banner-container {
    max-width: 90%;
  }

  .image-div {
    width: 100%;
  }

  .upload-container {
    flex-direction: column;
    align-items: start;
    gap: 10px;
    padding: 10px;
  }

  .upload-container input[type="text"] {
    width: 79vw;
  }
}
