.landing-page-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 90%;
  margin: 50px auto;
  background: aliceblue;
  padding: 20px;
}

.landing-page-box {
  width: 100%;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 10px;
}

.landing-page-buttons {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  padding: 10px 0px;
}

.landing-page-image img {
  width: 100%;
  height: auto;
}

.landing-page-preview-button {
  all: unset;
  cursor: pointer;
  background-color: green;
  color: #fff;
  padding: 8px 12px;
  border-radius: 10px;
  transition: transform 300ms linear;
}

.landing-page-preview-button:hover {
  scale: 1.05;
}

.landing-page-select-button {
  all: unset;
  cursor: pointer;
  background-color: blue;
  color: #fff;
  padding: 8px 12px;
  border-radius: 10px;
  transition: transform 300ms linear;
}

.landing-page-select-button:hover {
  scale: 1.05;
}

@media (max-width: 768px) {
  .landing-page-box {
    width: 100%;
  }
}
