.editTeamModalBackground {
  width: 100%;
  height: 100%;
  background-color: rgba(11, 11, 11, 0.059);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: first baseline;
  z-index: 2;
  top: 14%;
}

.editTeamModalContainer {
  width: 37%;
  height: auto;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.editTeamTitle {
  display: inline-block;
  text-align: center;
  margin-top: 10px;
}

.editTeamTitleCloseBtn {
  display: flex;
  justify-content: flex-end;
}

.editTeamTitleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

.editTeamFooter {
  flex: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.editTeamFooter button {
  width: 150px;
  height: 45px;
  margin: 10px;
  border: none;
  background-color: cornflowerblue;
  color: white;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
}

#editTeamCancelBtn {
  background-color: crimson;
}

@media (max-width: 880px) {
  .editTeamModalContainer {
    width: 87%;
  }
}
