@import url("https://fonts.googleapis.com/css2?family=Alkatra&family=Roboto+Condensed:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300&family=Ubuntu:ital,wght@1,300&display=swap");
.mainDashboard {
  height: 100%;
  width: 98%;
  margin: 23px auto;
  font-family: "Alkatra", cursive;
}
.dashboard-Video {
  background-color: #d9e7e3;
  width: 94%;
  display: flex;
  align-items: center;
  font-size: 15px;
  padding: 11px;
  justify-content: center;
  margin: 10px auto;
  border: 2px solid #ccc;
  border-radius: 3px;
}

.dashboard-item {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0px;
  margin: 0px;
}
.mainCity {
  width: 96%;
  background-color: #eef4f991;
  margin: 0 auto;
  border-radius: 3px;
  border: 2px solid #203f595c;
  overflow: hidden;
}
.CityTitle {
  background: #127b0aa6;
  color: aliceblue;
  text-align: center;
  font-family: "Quicksand", sans-serif;
}
.CityItem {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 8px;
  flex-wrap: wrap;
  margin-bottom: 8px;
}
.city-number {
  display: flex;
  padding: 4px;
}
.city {
  background: #5f9ea05e;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 7px;
  padding-bottom: 8px;
  border-radius: 3px 0px 0px 3px;
  margin: 0px;
  padding: 1px 7px 3px;
}
.cityNumber {
  background: #a596e5;
  color: aliceblue;
  border-radius: 0px 3px 3px 0px;
  padding: 1px 7px 3px;
  margin: 0px;
}
.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
.dash-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 110px;
  width: 250px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16);
  background-color: #fff;
  flex-direction: column;
  border-radius: 2px;
  border: 2px solid #aeb0b3c7;
  margin-left: 10px;
  padding: 42px;
  margin-right: 10px;
}

.dash-box h1 {
  margin: -2px;
  color: #666;
  font-size: 20px;
}
.dash-box p {
  font-size: 16px;
  margin: 0px;
  color: #666;
}

@media screen and (max-width: 768px) {
  .dashboard-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px;
    margin: 0px;
    flex-direction: column;
  }
  .dash-box {
    padding: 25px;
    margin: 6px;
    height: 100%;
    width: 100%;
  }
  .mainCity {
    width: 95.4%;
    margin: 0 auto;
  }
  .CityItem {
    margin: 0px;
    padding-bottom: 8px;
    padding-top: 5px;
    flex-wrap: wrap;
  }
  .city-number {
    padding: 5px;
  }
  .cityNumber {
    padding: 3px 8px 3px 7px;
    margin: 0px;
  }
  .city {
    padding: 3px;
    margin: 0px;
  }
  .dashboard-Video {
    width: 92.1%;
    padding: 6px;
    font-size: 13px;
  }
  .mainDashboard {
    width: 84%;
    margin: 9px auto;
  }
}
