/**
global style
*/

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap");

:root {
  --primary-bg-color: #254e6f;
  --primary-btn-color: #ff6700;
}

.landing-page-main-container,
.landing-page-main-container *,
.landing-page-main-container *::after,
.landing-page-main-container *::before {
  margin: 0;
  box-sizing: border-box;
}

html,
body {
  font-family: "Roboto", sans-serif;
}

body.landing-page-main-container {
  background: #fff !important;
}

.primary__button {
  all: unset;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.logo__image img {
  cursor: pointer;
  width: 80%;
}

.container {
  max-width: 1040px;
  margin: 0 auto;
  padding: 0px 20px;
}

.primary__button {
  width: fit-content;
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 1.2em;
  padding: 16px 32px;
  border-radius: 5px;
  background-color: var(--primary-btn-color);
}

.text-item {
  display: flex;
  align-items: start;
  gap: 10px;
}

.icon {
  font-size: 20px;
}

.separator {
  width: 80%;
  margin: 30px auto;
  border-bottom: 1px solid #fff;
}

/**
header style
*/

.header {
  background-color: black;
  padding: 30px 0px;
}

.header__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
}

/**
description style
*/

.description {
  background-color: var(--primary-bg-color);
  padding: 70px 0px;
}

.description__container {
  display: flex;
  align-items: start;
  justify-content: space-between;
  color: white;
  gap: 60px;
}

.description__text,
.description__image {
  width: 50%;
}

.description__image {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.description__image img {
  height: 402px;
}

.description__image__slider {
  width: 102%;
}

.description__image__slider img {
  width: 157px;
  height: 157px;
  border-radius: 20px;
}

.description__card__container {
  border: 2px solid black;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow: hidden;
}

.description__card__header {
  width: 100%;
  background-color: black;
  text-align: center;
  padding: 16px;
}

.description__card__price__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.description__card__price__section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.description__card__price__section h3 {
  text-decoration: line-through;
}

.description__card__price__text {
  color: var(--primary-btn-color);
}

.description__card__body {
  padding: 0px 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.description__card__footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  padding-bottom: 20px;
}

.description__card__review {
  display: flex;
  align-items: center;
  justify-content: center;
}

.description__card__review div {
  padding: 0px 10px;
  border-right: 1px solid white;
  white-space: nowrap;
}

.description__card__review div:last-child {
  border-right: 0;
}

.description__card__review__rating {
  display: flex;
  align-items: flex-start;
}

.image__card__container {
  border: 2px solid white;
  border-radius: 10px;
  padding: 10px;
  overflow: hidden;
}

.image__card__container img {
  width: 100%;
  object-fit: cover;
}

/**
features style
*/

.features__container {
  background-color: var(--primary-bg-color);
  margin: 70px 0px;
}

.features__card__container {
  padding: 70px 120px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.feature__card {
  padding: 80px 20px 20px 20px;
  border: 2px solid white;
  border-radius: 10px;
  overflow: hidden;
  color: white;
}

.feature__btn {
  width: 100%;
  margin: 30px auto 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.feature__image__slider {
  width: 99%;
  overflow: hidden;
  margin: 0 auto;
}
.feature__image__slider img {
  width: 243px;
  height: 243px;
  border-radius: 20px;
  overflow: hidden;
}

/**
subidha and subidha card style
*/

.effectiveness {
  background-color: white;
  margin: 20px 0px 70px 0px;
}

.effectiveness__container {
  padding: 0px 120px;
}

.effectiveness__card {
  background-color: var(--primary-bg-color);
  margin: 70px 0px;
}

.effectiveness__card__container {
  padding: 70px 0px;
  display: flex;
  align-items: start;
  justify-content: space-between;
  gap: 20px;
  color: #fff;
}

.text__card__image img {
  height: 313px;
  width: 100%;
}

.effectiveness__card__container .text__card__container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 33%;
  text-align: center;
}

.text__card__content h2 {
  margin-bottom: 10px;
}

.effectiveness__content__list {
  background-color: var(--primary-bg-color);
  padding: 70px 0px;
}

.effectiveness__content__list__container {
  padding: 0px 120px;
  color: #fff;
}

.effectiveness__content__list__card {
  border-radius: 20px;
  border: 2px solid #fff;
  overflow: hidden;
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/**
problems style
*/

.problems__container {
  padding: 40px 120px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

/**
questions style
*/

.questions {
  background-color: var(--primary-bg-color);
  padding: 70px 0;
  margin-bottom: 70px;
}

.questions__container {
  padding: 0 120px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  color: white;
}

/**
decisons style
*/

.decisions {
  background-color: var(--primary-bg-color);
  padding: 70px 0;
}

.decisions__container {
  padding: 0 120px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  color: white;
}

.footer {
  padding: 30px 0px;
  background-color: black;
  color: #fff;
}

/**
slider style
*/

.slider__btn__container {
  position: absolute;
  width: 98%;
  display: flex;
  justify-content: space-between;
}

.slider__image__button {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  background-color: black;
  opacity: 0.5;
  cursor: pointer;
  pointer-events: auto;
}

.slider__image__button.disabled {
  cursor: not-allowed;
  pointer-events: none;
}

/**
 accordion style
 */
.accordion {
  display: flex;
  flex-direction: column;
}

.accordion-item {
  border-bottom: 1px solid #ccc;
}

.accordion-header {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.icon {
  margin-right: 10px;
}

.accordion-content {
  height: 0;
  display: hidden;
  opacity: 0;
  transition: all 150ms linear;
  overflow: hidden;
}

.accordion-content.open {
  height: fit-content;
  padding: 10px 30px;
  opacity: 1;
}

.accordion-content.close {
  height: 0;
  padding: 0;
  opacity: 0;
}

/* variation container  */
.variation__container {
  background-color: #fff;
  padding: 70px 0px 0px;
}

.varinat__title {
  font-size: 28px;
  color: #000;
  font-weight: normal;
  padding-bottom: 20px;
  text-align: center;
}

.variant__cards {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;
}

.variant__card {
  height: 250px;
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border: 2px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  text-align: center;
  padding: 10px;
  font-size: 20px;
  text-transform: capitalize;
}

.variant__card img {
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 600px) {
  .container {
    width: 100%;
  }

  .header .primary__button {
    display: none;
  }

  .problems__container,
  .questions__container,
  .decisions__container,
  .effectiveness__container,
  .effectiveness__content__list__container {
    padding: 0px 10px;
  }

  .features__card__container {
    padding: 70px 10px;
  }

  .feature__image__slider img {
    width: 98%;
    height: auto;
  }

  .footer {
    padding: 30px 10px;
    text-align: center;
  }

  .effectiveness__card__container {
    padding: 70px 10px;
    flex-direction: column;
  }

  .effectiveness__card__container .text__card__container {
    width: 100%;
  }

  .description {
    padding: 70px 10px;
  }

  .description__container {
    flex-direction: column;
  }

  .description__text,
  .description__image {
    width: 100%;
  }

  .description__image__slider img {
    width: 98%;
    height: auto;
  }
}
