@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fasthand&display=swap");

:root {
  --mainColor: #29335c;
  --mainColorLight: #5767aa;
  --secondaryColor: #db2b39;
  --textColor: #eee;
}

.notification-header {
  background-color: brown;
  text-align: center;
  color: bisque;
  font-size: 20px;
  width: 98%;
  margin: 4px auto;
}

.notification-header p {
  padding: 5px;
  margin: 0px;
  text-transform: uppercase;
}
header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 55px;
  padding: 0px 6px 0px 0px;
  color: var(--textColor);
  background: linear-gradient(90deg, #207fc7, #2b3ed6);
}
.logoMain {
  width: 250px;
  height: 55px;
}
.h-logo {
  height: 100%;
  transition: 0.4s;
  cursor: pointer;
  text-align: center;
  padding: 6px;
  margin-left: 5px;
  color: #fff;
  font-family: "Alkatra", cursive;
  font-size: 33px;
}
/* .h-logo:hover {
  width: 204px;
  padding: 3px;
  transition: 0.4s;
} */
header h2:hover {
  padding: 0px 3px;
  transition: 0.2s;
}

nav a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  cursor: pointer;
  padding-right: 4px;
  font-size: 16px;
}

nav a:hover {
  color: #eee;
  text-decoration: underline;
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: #000;
  display: none;
  opacity: 0;
  font-size: 24px;
}

header div,
nav {
  display: flex;
  align-items: center;
}
.search-main {
  position: relative;
}

@media only screen and (max-width: 1024px) {
  header .nav-btn {
    display: inline-block;
    opacity: 1;
    color: #fff;
  }

  header nav {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2px;
    background: linear-gradient(90deg, #1654caf5, #3148fff7);
    transition: 0.7s;
    transform: translateX(200vh);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    z-index: 2;
  }

  header .responsive_nav {
    transform: none;
    z-index: 100;
  }

  nav .nav-close-btn {
    position: absolute;
    top: 2rem;
    right: 2rem;
  }

  nav a {
    font-size: 18px;
    color: #fff;
    margin: 8px;
  }
}
