.Business-Video {
  background-color: #d9e7e3;
  width: 98%;
  display: flex;
  align-items: center;
  padding: 4px;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: -15px;
  border-radius: 3px;
  font-size: 11px;
}
.Business {
  background-color: #f3fdfa;
  width: 98%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  flex-direction: column;
  margin-bottom: 20px;
  margin-top: 20px;
  border: 4px solid #fff;
}

.Business h1 {
  color: black;
  font-size: large;
  font-weight: bold;
}
.Business-Head {
  background-color: #f0f0f0eb;
  width: 100%;
}
.Business-Head-Current {
  background-color: #e4e4e4eb;
  width: 100%;
}
.Investor-Button {
  width: 90%;
  display: flex;
  justify-content: center;
  margin: 0 auto;
}
.Investor-Button button {
  background-color: #226f1b;
  padding: 8px;
  margin: 10px;
  border-radius: 9px;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
}
.Investor {
  width: 100%;
  overflow: hidden;
  background-color: #fff;
}
.action-button {
  margin: 7px;
  padding: 5px;
  background-color: #1976d2;
  color: #fff;
  border-radius: 7px;
  cursor: pointer;
}
.action-button-del {
  margin: 7px;
  padding: 5px;
  background-color: #db0e34;
  color: #fff;
  border-radius: 7px;
  cursor: pointer;
}
.InvestorList {
  display: flex;
  justify-content: space-between;
  width: 99%;
  background-color: #f0f0f0eb;
  padding: 6px;
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 9px;
}
.InvestorAmount {
  width: 100%;
  text-align: center;
  border-right: 1px solid #dbdbdb;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #a28e8e36;
}
.InvestorAmount:hover {
  background-color: #4ebf0e3d;
  cursor: pointer;
}
.removeBorder {
  border: none;
}
.InvestorAmountList {
  width: 100%;
  text-align: center;
  border-right: 1px solid #dbdbdb;
}
