.section-order {
  margin: 12px auto;
  width: 100%;
  border: 1px solid #cccccc7a;
}

.order-display {
  margin: 12px auto;
  width: 98%;
  background: 'azure';
  border: 1px solid #cccccc7a;
}

.colorRed {
  background-color: rgba(255, 0, 0, 0.619);
  padding: 10px;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
}

.order-fillter-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  flex-direction: column;
  border-bottom: 1px solid #cccccc7a;
}

.filter-2nd-order-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fbfbfba1;
  border-bottom: 1px solid #cccccc7a;
}

.datePicker {
  display: flex;
  background: #d4dada7d;
  padding: 5px;
  border-radius: 5px;
}

.datePicker p {
  margin: 0;
  padding-right: 5px;
}

.date-date-Picker {
  display: flex;
  align-items: center;
  background-color: #fff;
}

.date-date-Picker .react-datepicker-wrapper {
  width: auto;
}

.date-date-Picker input:first-of-type {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.date-date-Picker input:last-of-type {
  border-radius: 0;
}

.date-date-Picker input {
  width: 130px;
  padding: 6px 10px;
  border: 1px solid #cccccc7a;
  border-radius: 4px;
  font-size: 14px;
  color: #333;
  outline: none;
  background: #fff;
  height: 23px;
}

.date-date-Picker input:focus {
  position: relative;
  z-index: 1;
}

.date-date-Picker p {
  margin: 0;
  color: #155724;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 10px;
  border-top: 1px solid #cccccc7a;
  border-bottom: 1px solid #cccccc7a;
  height: 23px;
  display: flex;
  align-items: center;
}

.date-date-Picker .search-date {
  width: 32px;
  height: 28px;
  padding: 4px;
  cursor: pointer;
  color: #155724;
  background: #d4edda;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.date-date-Picker .search-date:hover {
  background: #c3e6cb;
}

.search-date {
  width: 100%;
  border: 1px solid #cccccc7a;
  background: aliceblue;
  margin: 0;
  padding: 0;
}

.when-select {
  background-color: #eeefff;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 13px;
  transition: all 10s ease-out;
}

.when-select-state {
  background-color: steelblue;
  padding: 6px;
  border-radius: 6px;
  color: aliceblue;
  cursor: pointer;
  font-size: small;
}

.sort-day {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px 13px;
  border-radius: 5px;
}

.how-many-order-will-show {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  gap: 0;
  height: 35px;
  border: 1px solid #cccccc7a;
  border-radius: 4px;
  background-color: #fff;
  padding-left: 18px;
  margin-right: 13px;
}

.how-many-order-will-show p {
  margin: 0;
  color: #155724;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 15px 6px 20px;
  border: none;
  border-right: 1px solid #cccccc7a;
  height: 35px;
  display: flex;
  align-items: center;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background: #fff;
}

.how-many-order-will-show select {
  padding: 6px 30px 6px 15px;
  border: none;
  font-size: 14px;
  color: #333;
  outline: none;
  background: #fff url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24'%3E%3Cpath fill='%23333' d='M7 10l5 5 5-5z'/%3E%3C/svg%3E") no-repeat right 8px center;
  height: 35px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  cursor: pointer;
  min-width: 70px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.date-filter {
  width: 150px;
  font-size: 14px;
  padding: 8px 35px 8px 12px;
  border: 1px solid #cccccc7a;
  border-radius: 4px;
  background: #fff url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24'%3E%3Cpath fill='%23333' d='M7 10l5 5 5-5z'/%3E%3C/svg%3E") no-repeat right 14px center;
  color: #333;
  outline: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
}

.sort-day-child {
  padding: 9px;
  background-color: #efefef;
  margin: 3px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.main-filter-status {
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #cccccc7a;
  width: 100%;
  background: rgb(255, 255, 255);
}

.main-filter-status ul {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 12px auto;
  padding: 0;
  width: 100%;
}

.main-filter-status ul li {
  list-style: none;
  padding: 7px;
  background-color: #e4e4e4;
  margin: 4px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 12px;
  color: #16161a;
}

.main-filter-status ul li.active {
  background-color: #2c974b;
  color: #efefef;
}

.main-filter-status ul li.active:hover {
  background-color: #17ab41;
  color: #efefef;
}

.main-filter-status ul li:hover {
  background-color: #f5e3e3;
}

.searchin {
  padding: 10px;
}

.delete {
  background-color: #ff0000c9;
}

.delete:hover {
  background-color: red;
}

.pagination-order-list {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 6px;
  width: 35%;
  margin: 15px auto;
  border-radius: 50px;
  border: 1px solid #cccccc7a;
}

.search {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #fff;
  width: 97%;
  margin: 17px auto;
  border-radius: 8px;
  border: 1px solid #cccccc7a;
  padding: 9px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.search input {
  flex-grow: 1;
  border: 1px solid #cccccc7a;
  outline: none;
  padding: 8px;
  border-radius: 5px;
  margin-right: 10px;
  font-size: 14px;
  background: #ffffff45;
}

.search-button {
  background-color: #d4edda;
  color: #155724;
  padding: 9px 53px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.search-button:hover {
  background-color: #c3e6cb;
}

.Show-Order {
  width: 80%;
  height: 100%;
  margin: 0 auto;
}

.Show-Order-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #fff;
}

.advance-report-btn-new {
  background-color: #d4edda;
  color: #155724;
  padding: 9px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.advance-report-btn-new:hover {
  background-color: #c3e6cb;
}

.otp-filter {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background: #fff;
  padding: 0px;
  gap: 10px;
  height: 32px;
}

.otp-filter-text {
  color: rgb(88, 82, 82);
  margin: 0;
  font-size: 15px;
  width: 100%;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
}

.order-filter-main {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  flex-direction: column;
  border-bottom: 1px solid #cccccc7a;
}

.filter-list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 12px auto;
  padding: 0;
  width: 100%;
}

.filter-item {
  list-style: none;
  padding: 7px;
  background-color: #e4e4e4;
  margin: 4px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 12px;
  color: #16161a;
}

.filter-item:hover {
  background-color: #f5e3e3;
}

.filter-item.active {
  background-color: #2c974b;
  color: #efefef;
}

.filter-item.active:hover {
  background-color: #17ab41;
}

.search-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #fff;
  width: 97%;
  margin: 17px auto;
  border-radius: 8px;
  border: 1px solid #cccccc7a;
  padding: 9px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.search-form {
  display: flex;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
}

.advance-report-btn {
  background-color: #d4edda;
  color: #155724;
  padding: 9px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.advance-report-btn:hover {
  background-color: #c3e6cb;
}

.search-input {
  flex-grow: 1;
  border: 1px solid #cccccc7a;
  outline: none;
  padding: 8px;
  border-radius: 5px;
  margin-right: 10px;
  margin-left: 10px;
  font-size: 14px;
  background: #ffffff45;
}

.search-button {
  background-color: #d4edda;
  color: #155724;
  padding: 9px 53px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.search-button:hover {
  background-color: #c3e6cb;
}

.date-date-Picker {
  display: flex;
}

.date-to-date-search {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 10px 15px;
  border-radius: 5px;
  border: 1px solid #cccccc7a;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  gap: 10px;
}

.date-to-date-search p {
  margin: 0;
  color: #155724;
  font-size: 14px;
  font-weight: 500;
}

.search-date {
  background-color: #d4edda;
  color: #155724;
  padding: 8px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  transition: background-color 0.2s;
}

.search-date:hover {
  background-color: #c3e6cb;
}

/* Mobile styles */
@media screen and (max-width: 768px) {
  .section-order {
    width: 98%;
  }
  .filter-2nd-order-list {
    flex-direction: column;
    background: white;
  }
  
  .date-date-Picker-mobile {
    display: none;
  }
  
  .how-many-order-will-show {
    width: 98%;
    background: white;
    border-radius: 0;
    padding: 0px;
    margin-bottom: 6px;
    margin-right: 0px;
    justify-content: space-between;
  }
  .how-many-order-will-show label{
    font-size: 13px;
    margin-left: 13px;
  }
  .how-many-order-will-show select{
  }
  .pagination-order-list {
    width: 95%;
  }
  
  .sort-day {
    width: 100%;
    padding: 0;
    margin: 0;
    border-bottom: 1px solid #cccccc7a;
    background: white;
    border-radius: 0;
  }
  
  .search {
    margin-bottom: 20px;
    flex-direction: column;
    width: 92%;
    background-color: #fff;
  }
  
  .search input {
    width: 99%;
    margin: 9px;
    padding: 9px;
    background: white;
  }
  
  .search-button {
    width: 100%;
    border-radius: 4px;
    background-color: #0000000f;
  }
  
  .advance-report-btn-new {
    width: 100%;
    background-color: #0000000f;
    margin: 0;
  }
  
  .main-filter-status ul {
    width: 100%;
    padding: 0;
    justify-content: center;
  }
  
  .main-filter-status ul li {
    margin: 4px;
    padding: 5px;
  }
  
  .date-picker {
    width: 98%;
    padding: 5px;
    text-align: center;
    border: 1px solid #cccccc7a;
    border-radius: 4px;
    overflow: hidden;
    font-size: 15px;
    background: #f2f2f2;
  }
  
  .date-filter {
    width: 98%;
    margin: 7px auto;
    padding: 8px 35px 8px 12px;
    font-size: 14px;
    border-radius: 0;
    background: #fff url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 24 24'%3E%3Cpath fill='%23333' d='M7 10l5 5 5-5z'/%3E%3C/svg%3E") no-repeat right 14px center;
  }
  
  .otp-filter {
    padding: 7px;
    gap: 5px;
    height: 41px;
    border-bottom: 1px solid #cccccc7a;
  }
  
  .otp-filter-text {
    font-size: 13px;
    border: 1px solid #cccccc7a;
    width: 100%;
    text-align: center;
    padding: 5px;
    border-radius: 5px;
  }
  
  .search-container {
    margin-bottom: 20px;
    flex-direction: column;
    width: 92%;
  }
  
  .search-input {
    width: 99%;
    margin: 6px auto;
    padding: 9px;
    background: white;
    border-radius: 1px;
    border: 1px solid #ccc;
  }
  
  .advance-report-btn {
    width: 100%;
    background-color: #0000000f;
    margin: 0;
  }
  
  .filter-list {
    width: 100%;
    padding: 0;
    justify-content: center;
  }
  
  .filter-item {
    margin: 4px;
    padding: 5px;
  }
  .date-date-Picker{
    display: flex;
    width: 98%;
    margin: 8px auto;
    padding: 0;
  }
  .date-date-Picker .react-datepicker-wrapper{
  }
  .date-date-Picker input{
    width: 96%;
    padding: 6px 2px;
    font-size: 13px;
    height: 22px;
    text-align: center;
  }
  .date-date-Picker p{
    font-size: 12px;
    height: 22px;
  }
  .date-date-Picker input:last-of-type{
   
  }
  .date-date-Picker .search-date{
    width: 12%;
    border-radius: 0;
  }
  .when-select{
    flex-direction: column;
    gap: 8px;
  }
  .when-select-state{
    width: 100%;
  }

}
