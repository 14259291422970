.banner-main {
  background-color: #f9f9f9;
  width: 96%;
  margin: 7px auto;
  min-height: 90vh;
}

.create-banner {
  align-items: center;
  background: linear-gradient(90deg, #555ea4, #555ea4);
  display: flex;
  justify-content: center;
  padding: 20px;
  border-radius: 0px;
}
.Banner-Video {
  background-color: #d9e7e3;
  width: 98%;
  display: flex;
  align-items: center;
  padding: 9px;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 0px;
  margin-bottom: 7px;
  border-radius: 3px;
  font-size: 17px;
  border: 2px solid #ccc;
}
.no-data-available {
  text-align: center;
  padding: 20px;
  font-weight: bold;
  color: #777;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

@media (max-width: 880px) {
  .banner-main {
    width: 95%;
    padding: 0px;
  }
  .Banner-Video {
    width: 94.4%;
    font-size: 13px;
  }
}
