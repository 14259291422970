.Purchase-Video {
  background-color: #d9e7e3;
  width: 85%;
  display: flex;
  align-items: center;
  padding: 4px;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 0px;
  margin-bottom: -8px;
  border-radius: 3px;
  font-size: 11px;
}
