.category-select-main {
  height: auto;
  width: 96%;
  margin: 7px auto;
}

.category-select-video {
  background-color: #d9e7e3;
  width: 98%;
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: center;
  margin: 0 auto;
  border-radius: 3px;
  font-size: 15px;
  border: 2px solid #ccc;
  margin-bottom: 7px;
}

.category-select-create {
  align-items: center;
  background: linear-gradient(90deg, #555ea4, #555ea4);
  display: flex;
  justify-content: center;
  padding: 14px;
  border-radius: 1px;
}

.category-select-no-data {
  width: 100%;
  padding: 12px;
  text-align: center;
  font-weight: 500;
}

@media (max-width: 880px) {
  .category-select-main {
    margin: 7px auto;
  }
  .category-select-video {
    width: 93%;
    font-size: 13px;
  }
}
