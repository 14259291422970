@import url('https://fonts.googleapis.com/css2?family=Fasthand&family=Inter:wght@200&family=Kalam:wght@300&display=swap');

.login-footer-main{
  display: flex;
  justify-content: space-between;
  background: linear-gradient(90deg,#207fc7,#4697d4);
  color: #fff;
	font-family: 'Fasthand', cursive;
	font-family: 'Inter', sans-serif;
	font-family: 'Kalam', cursive;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 18px;
}
.copyright a{
color: #fff;
}
.Developed a{
  color: #fff;
}
.make-money a{
  color: #fff;
}
.login-footer-facebook a{
  color: #fff;
}
.login-footer-youtube a{
  color: #fff;
}
.copy-dev{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.copyright{
  color: #fff;
  }
  .Developed{

  }

.login-footer-right{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.login-footer-youtube{
  margin-left: 10px;
}
  @media (max-width: 880px) {
    .login-footer-main{
      padding: 0px;
      flex-direction: column;
      text-align: center;
      padding-top: 15px;
      padding-bottom: 10px;
    }
   .login-footer-right{
    flex-direction: column;
    align-items: center;
   }
   .login-footer-youtube{
    margin-left: 0px;
  }
  }
  
  