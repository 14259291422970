.category-main-1234 {
  margin: 0 auto;
  height: auto;
  width: 97%;
  box-shadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px";
}

.category-video-1234 {
  background-color: #d9e7e3;
  width: 40%;
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: center;
  margin: 20px auto;
  border-radius: 30px;
  font-size: 15px;
}

.create-category-1234 {
  align-items: center;
  background: linear-gradient(90deg, #555ea4, #555ea4);
  display: flex;
  justify-content: center;
  padding: 14px;
  border-radius: 10px 10px 0px 0px;
}

.sub-category-back-1234 {
  align-items: center;
  background: linear-gradient(90deg, #555ea4, #555ea4);
  display: flex;
  justify-content: center;
  padding: 14px;
  border-radius: 0px 0px 10px 10px;
}
.slider-container-1234 {
  padding: 20px;
  display: none;
  flex-direction: column;
  justify-content: center;
}

.slider-container-1234 p {
  font-size: 17px;
  text-align: center;
  margin: 0;
}

.no-data-available-1234 {
  width: 100%;
  padding: 12px;
  text-align: center;
  font-weight: 500;
}

.category-table-container-1234 {
  width: 100%;
  margin: 0 auto;
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.category-table-1234 {
  width: 100%;
  margin-top: 20px;
}

.category-table-1234 th,
.category-table-1234 td {
  padding: 10px;
  text-align: center;
  width: auto; /* Set width to auto */
}

.category-table-1234 img {
  border-radius: 5px;
}

.category-status-button-1234 {
  background-color: green;
  color: #fff;
  padding: 4px;
  font-size: 12px;
}

.category-edit-button-1234 {
  background-color: #318e31d9;
  color: #fff;
  padding: 4px;
  font-size: 12px;
}

.category-subcategory-button-1234 {
  background-color: #007bff;
  color: #fff;
  padding: 4px;
  font-size: 12px;
}

.category-delete-button-1234 {
  background-color: red;
  color: #fff;
  padding: 4px;
  font-size: 12px;
}

.table-cell-nowrap-1234 {
  white-space: nowrap;
}

.subcategory-badge {
  position: absolute;
  top: -13px;
  right: -18px;
  background-color: rgb(198, 64, 127);
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
}

@media (max-width: 880px) {
  .slider-container-1234 {
    display: flex; /* Display the slider only on mobile view */
  }

  .category-video-1234 {
    width: 95%;
    border-radius: 3px;
    font-size: 14px;
    padding: 6px;
    border: 2px solid #ccc;
    text-align: center;
    margin: 7px auto;
  }
  .category-table-container-1234 {
    padding: 5px;
  }

  .category-table-1234 th,
  .category-table-1234 td {
    padding: 5px;
    font-size: 12px;
  }

  .category-status-button-1234,
  .category-edit-button-1234,
  .category-subcategory-button-1234,
  .category-delete-button-1234 {
    padding: 2px;
    font-size: 10px;
  }
  .create-category-1234 {
    padding: 12px;
    border-radius: 5px 5px 0px 0px;
  }
}
