.category-main{
    height: auto;
    width: 85%;
    margin: 0 auto;
    padding-top: 20px;
}

.create-category{
    align-items: center;
background-color: #e0f0ff61;
display: flex;
justify-content: center;
padding: 20px;
border-radius: 5px;
margin-bottom: -3px;
}

.category-button{
    padding: 0px;
}
  
@media (max-width: 880px) {
    .category-main{
        margin: 43px auto;
    }
}