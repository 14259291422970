.colorRed{
background-color: rgba(255, 0, 0, 0.619);
padding: 10px;
border: none;
border-radius: 5px;
color: #fff;
cursor: pointer;
}
.fillter-main{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    padding-right: 21px;
}
.filter-2nd{
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: #eeefff;
    border-bottom: 1px solid #ccc;
    padding-top: 8px;
    padding-bottom: 8px;
}
    
.sort-day{
    display: flex;
    align-items: center;
    justify-content: center;
}
.sort-day-child{
    padding: 9px;
    background-color: #efefef;
    margin: 3px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px; 
}
.filter-status{
    display: flex;
    align-items: center;
    justify-content: center;
}
.filter-status ul{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: -21px;
}

.filter-status ul li{
    list-style: none;
    padding: 7px;
    background-color: #efefef;
    margin: 2px;
    border-radius: 10px;
    cursor: pointer;
    font-size: 13px;
}
.filter-status ul li.active{
    background-color: #2c974b;
    color: #efefef;
}
.filter-status ul li.active:hover{
    background-color: #17ab41;
    color: #efefef;
}

.filter-status ul li:hover{
    background-color: #f5e3e3;
}

.searchin{
    padding: 10px;
}
.delete{
    background-color: #ff0000c9;
}

.delete:hover{
    background-color: red;
}
.pagination-order{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    padding: 6px;
    width: 35%;
    margin: 15px auto;
    border-radius: 50px;
}

.Show-Order{
    width: 90%;
    height: 100%;
    margin: 0 auto;
}
.Show-Order-container{
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #fff;
}