.PrintModalBackground {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.PrintModalContainer {
  width: 37%;
  height: 70%;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow: auto;
}

.PrintModalContainer .title {
  display: inline-block;
  text-align: center;
  margin-top: 10px;
}
.title h1 {
  color: #000;
}
.titleCloseBtn {
  display: flex;
  justify-content: flex-end;
}

.titleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

.PrintModalContainer .body {
  flex: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.7rem;
  text-align: center;
}

.PrintModalContainer .PrintFooter {
  flex: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.PrintModalContainer .PrintFooter button {
  width: 150px;
  height: 45px;
  margin: 10px;
  border: none;
  background-color: cornflowerblue;
  color: white;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
}
.PrintInvoice {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
}
.InvoiceTitle {
  font: bold 100% sans-serif;
  letter-spacing: 0.5em;
  text-align: center;
  text-transform: uppercase;
  background-color: #000;
  color: #fff;
  text-align: center;
}
.LogoDate {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 30px;
  border-bottom: 1px solid #cccc;
  padding-bottom: 12px;
}
.FromTo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 30px;
  padding-bottom: 20px;
}

.productList table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.productList td,
th {
  border: 1px solid #dddddd;
  text-align: center;
  padding: 8px;
  color: #000;
  font-size: 10px;
  font-family: "Alkatra";
}

.productList tr:nth-child(even) {
  background-color: #dddddd;
}
.InvoiceCal {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: 5px solid #330707;
  margin-top: 17px;
  margin-bottom: 10px;
}
.InvoiceCalItem {
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #ccc;
  font-family: auto;
}
.removeBorder {
  border: none;
}
#cancelBtn {
  background-color: crimson;
}
.Location-View-Modal {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
