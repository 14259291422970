.landing-main {
  height: auto;
  min-height: 90vh;
  width: 96%;
  margin: 0px auto;
}

.landing-video {
  background-color: #d9e7e3;
  width: 98.2%;
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: center;
  margin: 10px auto;
  border-radius: 3px;
  font-size: 15px;
  border: 2px solid #ccc;
}

.create-landing {
  align-items: center;
  background: linear-gradient(90deg, #555ea4, #555ea4);
  display: flex;
  justify-content: center;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: -3px;
}

.no-data-available {
  width: 100%;
  padding: 12px;
  text-align: center;
  font-weight: 500;
}

@media (max-width: 880px) {
  .landing-main {
    margin: 1px auto;
  }
  .landing-video {
    width: 93.2%;
    font-size: 14px;
  }
}
