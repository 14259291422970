.dns-main {
  margin: 0 auto;
  height: auto;
  width: 97%;
  box-shadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px";
}

.dns-video {
  background-color: #d9e7e3;
  width: 40%;
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: center;
  margin: 20px auto;
  border-radius: 30px;
  font-size: 15px;
}

.dns-create {
  align-items: center;
  background: linear-gradient(90deg, #555ea4, #555ea4);
  display: flex;
  justify-content: center;
  padding: 14px;
  border-radius: 10px 10px 0px 0px;
}

.dns-slider-container {
  padding: 20px;
  display: none;
  flex-direction: column;
  justify-content: center;
}

.dns-slider-container p {
  font-size: 17px;
  text-align: center;
  margin: 0;
}

.dns-no-data {
  width: 100%;
  padding: 12px;
  text-align: center;
  font-weight: 500;
}

.dns-table-container {
  width: 100%;
  margin: 0 auto;
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.dns-table {
  width: 100%;
  margin-top: 20px;
}

.dns-table th,
.dns-table td {
  padding: 10px;
  text-align: center;
  width: auto;
}

.dns-table img {
  border-radius: 5px;
}

.dns-status-button {
  background-color: green;
  color: #fff;
  padding: 4px;
  font-size: 12px;
}

.dns-edit-button {
  background-color: #318e31d9;
  color: #fff;
  padding: 4px;
  font-size: 12px;
}

.dns-subcategory-button {
  background-color: #007bff;
  color: #fff;
  padding: 4px;
  font-size: 12px;
}

.dns-delete-button {
  background-color: red;
  color: #fff;
  padding: 4px;
  font-size: 12px;
}

.dns-cell-nowrap {
  white-space: nowrap;
}

.dns-subcategory-badge {
  position: absolute;
  top: -13px;
  right: -18px;
  background-color: rgb(198, 64, 127);
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
}

@media (max-width: 880px) {
  .dns-slider-container {
    display: flex;
  }

  .dns-video {
    width: 91%;
    border-radius: 3px;
    font-size: 16px;
    padding: 12px;
    border: 2px solid #ccc;
    text-align: center;
  }
  .dns-table-container {
    padding: 5px;
  }

  .dns-table th,
  .dns-table td {
    padding: 5px;
    font-size: 12px;
  }

  .dns-status-button,
  .dns-edit-button,
  .dns-subcategory-button,
  .dns-delete-button {
    padding: 2px;
    font-size: 10px;
  }
  .dns-create {
    padding: 12px;
    border-radius: 5px 5px 0px 0px;
  }
}
