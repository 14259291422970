.add-product{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 42px;
}

.add-sub{
    display: flex;
    flex-direction: column;
    height: 40%;
    width: 56%;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;    padding: 20px;
    margin-top: 40px;
    text-align: center;
    color: #fff;
    opacity: 75%
}
.add-sub h3{
    margin-bottom: 20px;
}

.sub-button{
    display: inline-block;
    outline: 0;
    cursor: pointer;
    padding: 9px 1px;
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
    vertical-align: middle;
    border: 1px solid;
    border-radius: 6px;
    color: #ffffff;
    background-color: #2ea44f;
    border-color: #1b1f2326;
    box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px 0px, rgba(255, 255, 255, 0.25) 0px 1px 0px 0px inset;
    transition: 0.2s cubic-bezier(0.3, 0, 0.5, 1);
    transition-property: color, background-color, border-color;
    margin-top: 15px;
}
.sub-button:hover{

        background-color: #2c974b;
        border-color: #1b1f2326;
        transition-duration: 0.1s;
}
.form__label {
    font-family: 'Roboto', sans-serif;
    font-size: 1.2rem;
    margin-left: 2rem;
    margin-top: 0.7rem;
    display: block;
    transition: all 0.3s;
    transform: translateY(0rem);
  }
  
  .form__input {
    font-family: 'Roboto', sans-serif;
    color: #333;
    font-size: 1.2rem;
      margin: 0 auto;
    padding: 0.5rem 1rem;
    border-radius: 0.2rem;
    background-color: rgb(255, 255, 255);
    border: none;
    width: 90%;
    display: block;
    border-bottom: 0.3rem solid transparent;
    transition: all 0.3s;
    margin-bottom: 8px;
  }
  
  .form__input:placeholder-shown + .form__label {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(-4rem);
    transform: translateY(-4rem);
  }
.myFile{
  padding-left: 26px;
padding-top: 10px;
}