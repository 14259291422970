@import url("https://fonts.googleapis.com/css2?family=Alkatra&family=Roboto+Condensed:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@300&family=Ubuntu:ital,wght@1,300&display=swap");
.mainDashboard {
  height: 100%;
  width: 87%;
  margin: 23px auto;
  font-family: "Alkatra", cursive;
}

.dashboard-item {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0px;
  margin: 0px;
}
.mainCity {
  width: 86%;
  background-color: aliceblue;
  margin: 0 auto;
  border-radius: 5px;
}
.CityTitle {
  background: #127b0aa6;
  color: aliceblue;
  text-align: center;
  font-family: "Quicksand", sans-serif;
}
.CityItem {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: -16px;
  flex-wrap: wrap;
}
.city-number {
  display: flex;
  padding: 4px;
}
.city {
  background: #5f9ea05e;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 7px;
  padding-bottom: 8px;
  border-radius: 3px 0px 0px 3px;
  margin: 0px;
  padding: 1px 7px 3px;
}
.cityNumber {
  background: #a596e5;
  color: aliceblue;
  border-radius: 0px 3px 3px 0px;
  padding: 1px 7px 3px;
  margin: 0px;
}
.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
.dash-box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 110px;
  width: 250px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16);
  background-color: #fff;
  flex-direction: column;
  border-radius: 2%;
  border: 2px solid aliceblue;
  margin-left: 10px;
  padding: 42px;
  margin-right: 10px;
}

.dash-box h1 {
  margin: -2px;
  color: #666;
  font-size: 20px;
}
.dash-box p {
  font-size: 16px;
  margin: 0px;
  color: #666;
}

@media screen and (max-width: 768px) {
  .dashboard-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px;
    margin: 0px;
    flex-direction: column;
  }
  .dash-box {
    padding: 25px;
    margin: 10px;
    height: 100%;
    width: 100%;
  }
  .mainCity {
    width: 98%;
    margin: 0 auto;
  }
  .CityItem {
    margin: 0px;
    padding-bottom: 20px;
    flex-wrap: wrap;
  }
  .city-number {
    padding: 5px;
  }
  .cityNumber {
    padding: 3px;
    margin: 0px;
  }
  .city {
    padding: 3px;
    margin: 0px;
  }
}
