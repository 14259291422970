.landing-page-edit-btn {
  all: unset;
  cursor: pointer;
  padding: 10px 20px;
  width: fit-content;
  border-radius: 4px;
  background-color: green;
  color: #fff;
}

.landing-page-edit-btn-position {
  margin-bottom: 30px;
}

.changing-description-container {
  display: flex;
  flex-direction: column;
}

.changing-description {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.changing-description-title {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-bottom: 12px;
}

.changing-description-input {
  width: 350px;
  flex: 1;
  margin-right: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.changing-description-textarea {
  width: 350px;
  min-height: 150px !important;
  flex: 1;
  margin-right: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.changing-description-button {
  color: red !important;
  cursor: pointer;
}

.changing-description-add-button {
  width: fit-content;
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
}

.changing-description-cancel {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 10px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
}

.changing-description-update {
  background-color: #008cba;
  color: white;
  border: none;
  padding: 10px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
}

.changing-slider-image,
.changing-slider-preview,
.changing-item-image {
  height: 200px;
  width: 200px;
  border-radius: 5px;
}

.changing-faq-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.changing-faq-content {
  display: flex;
  align-items: center;
  gap: 10px;
}

.changing-faq-inputs {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.changing-slider,
.changing-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.changing-slider-inputs {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@media all and (max-width: 768px) {
  .changing-description-textarea,
  .changing-description-input {
    width: 100%;
  }
}
