.OrderViewModalBackground {
  width: 98%;
  height: 75vh;
  background-color: rgba(200, 200, 200, 0);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.OrderViewModalContainer {
  width: 500px;
  height: 596px;
  border-radius: 5px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow-y: scroll;
}
.OrderViewModalImageQntMainFlex {
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: #fff;
  border-radius: 11px;
  color: #050404;
  flex-wrap: wrap;
}
.OrderViewModalImgP {
  align-items: center;
  display: flex;
  justify-content: center;
  border: 1px solid #ccc;
  flex-direction: column;
  margin: 4px;
  padding: 5px;
}
.OrderViewModalTitle {
  display: inline-block;
  text-align: center;
  margin-top: 10px;
}

.OrderViewModalTitle h1 {
  color: #000;
}

.OrderViewModalTitleCloseBtn {
  display: flex;
  justify-content: flex-end;
}

.OrderViewModalTitleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

.OrderViewModalBody {
  flex: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.7rem;
  text-align: center;
}

.OrderViewModalFooter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.OrderViewModalOrderEditStatus {
  padding: 10px;
  margin-bottom: 10px;
}
.OrderViewModalFooter button {
  width: 150px;
  height: 45px;
  margin: 10px;
  border: none;
  background-color: cornflowerblue;
  color: white;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
  margin-top: 20px;
}

#OrderViewModalCancelBtn {
  background-color: crimson;
}

.OrderViewModalLocationViewModal {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.OrderViewModalDatePicker {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
