@import url('https://fonts.googleapis.com/css2?family=Fasthand&family=Inter:wght@200&family=Kalam:wght@300&display=swap');
.login-container{
	height: 100%;
	width: 100%;
	background-color: aliceblue;
	font-family: 'Fasthand', cursive;
	font-family: 'Inter', sans-serif;
	font-family: 'Kalam', cursive;
}

.header-login{
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 0 auto;
	padding-left: 80px;
	padding-right: 80px;
	background: linear-gradient(90deg,#207fc7,#2b3ed6);
	color: #fff;
}

.logo-main h2{
	margin: 0px;
    padding: 3px;
    font-size: 29px;
	cursor: pointer;
}
.login-main ul{
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
	cursor: pointer;
}
.login-main ul li{
	list-style: none;
	padding: 10px;
	font-size: 20px;
}
.login-signup-header{
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
	width: 45%;
	border-radius: 5px;
	font-size: 16px;
	margin: 20px auto;
}
.login-signup-main{
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 90%;
	margin: 0px auto;
	height: 90vh;

}
.login-or-signup{
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
	flex-direction: column;
	width: 45%;
	border-radius: 8px;
	background-color: #fff;
	-webkit-transition: 300ms all cubic-bezier(0.4, 0, 0.2, 1);
	transition: 300ms all cubic-bezier(0.4, 0, 0.2, 1);
	box-shadow: 0 8px 60px 0 rgb(103 151 255 / 11%),
	  0 12px 90px 0 rgb(103 151 255 / 11%);
	margin-top: 24px;
	overflow: hidden;
}
.login-signup-tab{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
}
.login-tab{
	background-color: #0d6cb4;
	width: 50%;
	text-align: center;
	color: #fff;
	font-size: 28px;
	cursor: pointer;
	height: auto;
	transition: height 2s;
}
.login-tab-active{
	background-color: #207fc7;
	width: 50%;
	text-align: center;
	color: #fff;
	font-size: 28px;
	cursor: pointer;
	height: auto;
	transition: height 2s;

}
.signup-tab{
	background-color: #0d6cb4;
	width: 50%;
	text-align: center;
	color: #fff;
	font-size: 28px;
	cursor: pointer;
	height: auto;
	transition: height 2s;


}
.signup-tab-active{
	background-color: #207fc7;
	width: 50%;
	text-align: center;
	color: #fff;
	font-size: 28px;
	cursor: pointer;
	height: auto;
	transition: height 2s;
	transition: height 2s;

}
.login-inputs{
    width: 90%;
	margin-bottom: 32px;
}
.signup-inputs{
	width: 90%;
	margin-bottom: 32px;
}
.login-inputs input[type="number"],
.signup-inputs input[type="number"],
.signup-inputs input[type="text"],
.signup-inputs input[type="password"],
.login-inputs input[type="password"] {
  display: block;
  border: none;
  border-radius: 5px;
  background: rgba(109, 124, 144, 0.1);
  width: 90%;
  margin: 20px 0;
  padding: 8px 20px;
  text-align: left;
  border: 2px solid transparent;
  transition: 300ms all cubic-bezier(0.4, 0, 0.2, 1);
  line-height: 1.8em;
  letter-spacing: 0.5px;
  font-weight: 700;
  color: #20292f;
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.login-btn {
	width: 100%;
	border: 0;
	outline: 0;
	border-radius: 9999px;
	padding: 10px 0;
	color: #fff;
	text-align: center;
	text-transform: uppercase;
	font-weight: bold;
	background: linear-gradient(120deg, rgba(103, 151, 255, 0) 0%, #4d86ff 100%);
	background-color: #67b5ff;
	cursor: pointer;
  }
@media screen and (max-width: 768px) {
	.login-container{
		background-color: #c6c6c6;
	}
	.header-login{
	padding-left: 10px;
	padding-right: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	}
	.logo-main{
		font-size: 15px;
	}
	.logo-main h2{
		margin: 0px;
		padding: 3px;
		font-size: 27px;
	}

	.login-main{
		display: none;
	}
	.login-main ul li{
			font-size: 16px;
	}
	.login-signup-main{
		width: 100%;
		height: 70vh;
	}
	.login-or-signup{
		width: 97%;
	}
	.login-inputs input[type="number"],
	.signup-inputs input[type="number"],
	.signup-inputs input[type="text"],
	.signup-inputs input[type="password"],
	.login-inputs input[type="password"] {
	padding: 8px 15px;
	}
	.login-inputs input[type="number"],
	.signup-inputs input[type="number"],
	.signup-inputs input[type="text"],
	.signup-inputs input[type="password"],
	.login-inputs input[type="password"] {
	padding: 8px 15px;
	}
}