.category-main {
  height: auto;
  width: 85%;
  margin: 0 auto;
  padding-top: 10px;
}

.domain-Video {
  background-color: #d9e7e3;
  width: 84%;
  display: flex;
  align-items: center;
  padding: 4px;
  justify-content: center;
  margin: 0 auto;
  border-radius: 3px;
  font-size: 11px;
}
.create-category {
  align-items: center;
  background-color: #e0f0ff61;
  display: flex;
  justify-content: center;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: -3px;
}

.category-button {
  padding: 0px;
}
