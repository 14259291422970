.OrderReport {
  background-color: rgb(255, 255, 255);
  width: 98%;
  height: 100vh;
  margin: 0 auto;
  overflow: scroll;
}
.orderReportMin {
  display: flex;
  width: 20%;
}
.orderProduct {
}
