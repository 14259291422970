.search-desktop-container {
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.search-desktop-input {
  display: flex;
  align-items: center;
  grid-area: 16px;
  width: 50%;
  border: 1px solid #ccc;
  padding: 10px 20px;
  background-color: #fff;
  border-radius: 9999px;
}

.search-desktop-input input {
  width: 100%;
  border: none;
  outline: none;
  margin-right: 16px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.72);
}

.search-desktop-search-icon {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.62);
}

.search-desktop-close-icon {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.62);
  cursor: pointer;
}

/* seach result  */

.search-result-container {
  width: 90%;
  margin: 0 auto;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.search-no-result-found {
  font-size: 18px;
  margin-top: 20px;
}

@media all and (max-width: 768px) {
  .search-result-container {
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
    gap: 5px;
    padding: 5px;
  }

  .search-desktop-input {
    width: 85%;
  }

  .search-no-result-found {
    width: 100%;
    font-size: 18px;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 10px;
  }

  .margin-bottom-find-result {
    margin-bottom: 10px;
  }
}
