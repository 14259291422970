.mainTheme {
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  padding: 0px;
  width: 96%;
  margin: 15px auto;
}

.ThemeContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
}

.ThemeHeaderFooter {
  width: 48%;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border: 3px solid #e14a4a;
  border-radius: 6px;
  overflow: hidden;
}

.ThemeHeaderFooter h3 {
  padding: 8px;
  background-color: rgba(14, 66, 132, 0.85);
  border-bottom: 1px solid #e0e0e0;
  margin: 0px;
  color: #e0e0e0;
}
.saveTheme {
  background-color: #0e4284;
  color: #fff;
  cursor: pointer;
  padding: 8px;
  border: none;
  border-radius: 6px;
  margin-bottom: 30px;
  font-size: 17px;
}
@media only screen and (max-width: 1024px) {
  .mainTheme {
    height: auto;
    flex-direction: column;
    margin: 0px auto;
    padding: 0px;
  }
  .ThemeContainer {
    width: 100%;
    flex-direction: column;
    margin-top: 0px;
  }
  .ThemeHeaderFooter {
    width: 96%;
    margin: 0 auto;
    border: 3px solid #1535e194;
    border-radius: 6px;
    overflow: hidden;
  }
}
