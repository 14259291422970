@import url("https://fonts.googleapis.com/css2?family=Alkatra&display=swap");
.main-header {
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(90deg, #207fc7, #2b3ed6);
}
.menu-icon {
  display: none;
}
.logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 20px;
}

.logo img {
  height: 50px;
  width: 50px;
}
.logo-text {
  color: #fff;
  font-family: "Alkatra", cursive;
  font-size: 33px;
}
.nav {
  font-family: "Alkatra", cursive;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 20px;
  height: 65px;
}
.nav ul {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.nav ul li {
  padding: 5px;
  list-style: none;
  color: #fff;
  font-size: 18px;
}
.sub-menue {
  display: none;
}
.nav ul li:hover .sub-menue {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  background-color: #2b3ecc;
  border-radius: 5px;
  cursor: pointer;
  margin-left: -40px;
  z-index: 5;
}
.nav ul li:hover .sub-menue ul {
  display: block;
  margin: 10px;
}
.nav ul li:hover .sub-menue ul li {
  width: 150px;
  padding: 5px;
}
.nav ul li:hover .sub-menue ul li:hover {
  color: #ff8447;
}
a ul li:hover {
  color: #ff8447;
  cursor: pointer;
}

.mobile-menu {
  display: hidden;
}

.mobile-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 3px;
}
.avatar {
  overflow: hidden;
  border-radius: 50%;
  background-color: antiquewhite;
  padding: 5px;
  height: 30px;
  width: 30px;
  margin-left: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 768px) {
  .main-header {
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .logo {
    align-self: flex-start;
    /* padding-top: 14px; */
  }

  .nav {
    width: 100%;
    height: 55vh;
    background-color: #110f10;
    position: absolute;
    left: -100%;
    top: 60px;
    z-index: 999999;
    animation: open 200ms linear;
  }

  .active {
    left: 0px;
    top: 60px;
  }

  .nav ul {
    padding: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .menu-icon {
    display: block;
  }
  .mobile-menu {
    display: block;
    color: #fff;
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .mobile-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0px;
  }

  .menu-icon {
    color: #fff;
    padding-right: 20px;
  }
  .avatar {
    background-color: #3f99dd6e;
    border-radius: 5px;
    width: 80%;
    height: auto;
    margin: 0 auto;
  }
  .avatar img {
    border-radius: 50%;
    border: 2px solid rgb(230, 226, 217);
  }
}
