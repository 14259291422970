.ExpenseBG {
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: first baseline;
  z-index: 2;
  top: 14%;
}

.ExpenseModalContainer {
  width: 37%;
  height: auto;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.ExpenseModalContainer .title {
  display: inline-block;
  text-align: center;
  margin-top: 10px;
}

.title h1 {
  color: #000;
}
.titleCloseBtn {
  display: flex;
  justify-content: flex-end;
}

.titleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}

.ExpenseModalContainer .body {
  flex: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.7rem;
  text-align: center;
}

.ExpenseModalContainer .footer {
  flex: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.ExpenseModalContainer .footer button {
  width: 150px;
  height: 45px;
  margin: 10px;
  border: none;
  background-color: cornflowerblue;
  color: white;
  border-radius: 8px;
  font-size: 20px;
  cursor: pointer;
}

#cancelBtn {
  background-color: crimson;
}

@media (max-width: 880px) {
  .ExpenseBG {
    width: 100%;
    top: 8px;
  }
  .ExpenseModalContainer {
    width: 86%;
    height: auto;
  }
}
