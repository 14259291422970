.customer-main {
  height: auto;
  width: 96%;
  margin: 0 auto;
  padding-top: 7px;
}

.customer-video {
  background-color: #d9e7e3;
  width: 94.4%;
  display: flex;
  align-items: center;
  padding: 9px;
  justify-content: center;
  margin: 0 auto;
  border-radius: 3px;
  font-size: 15px;
  border: 2px solid #ccc;
}

.create-customer {
  /* Updated */
  align-items: center;
  background: linear-gradient(90deg, #555ea4, #555ea4);
  display: flex;
  justify-content: center;
  padding: 14px;
  border-radius: 1px;
}

.pagination-customer {
  /* Updated */
  margin: 20px auto;
  display: flex;
  justify-content: center;
}

.no-data-available {
  text-align: center;
  font-size: 14px;
  color: gray;
  margin: 20px 0;
}

@media (max-width: 880px) {
  .customer-main {
    /* Updated */
    margin: 0px auto;
  }
  .customer-video {
    background-color: #d9e7e3;
    width: 90%;
    display: flex;
    align-items: center;
    padding: 9px;
    justify-content: center;
    margin: 0 auto;
    border-radius: 3px;
    font-size: 15px;
    border: 2px solid #ccc;
  }
}
