.product-Video {
  background-color: #d9e7e3;
  width: 96.5%;
  display: flex;
  align-items: center;
  padding: 7px;
  justify-content: center;
  margin: 10px auto;
  border-radius: 3px;
  font-size: 16px;
  border: 2px solid #ccc;
}
h1 {
  font-size: 30px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 300;
  text-align: center;
  margin-bottom: 15px;
}
table {
  width: 100%;
  table-layout: fixed;
}
.tbl-header {
  background-color: rgba(255, 255, 255, 0.3);
}
.tbl-content {
  min-height: 300px;
  max-height: 500px;
  overflow-x: auto;
  margin-top: 0px;
  border: 1px solid rgba(255, 255, 255, 0.95);
  background: #214439ad;
}
tr:hover {
  cursor: pointer;
  background: #4b515970;
}
th {
  padding: 20px 15px;
  text-align: center;
  font-weight: 500;
  font-size: 12px;
  color: #fff;
  text-transform: uppercase;
}
td {
  padding: 6px;
  text-align: center;
  vertical-align: middle;
  font-weight: 300;
  font-size: 16px;
  color: #fff;
  border-bottom: solid 1px rgba(255, 255, 255, 0.1);
}

.td-button {
  background-color: #8167ff;
  padding: 10px;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  margin-left: 3px;
}
.td-d-button {
  background-color: #ff0808a9;
}
.td-button:hover {
  background-color: #2a05e2d5;
}
.td-d-button:hover {
  background-color: #fb0606;
}
/* demo styles */
section {
  margin: 12px auto;
  width: 98%;
}

/* follow me template */
.made-with-love {
  margin-top: 40px;
  padding: 10px;
  clear: left;
  text-align: center;
  font-size: 10px;
  font-family: arial;
  color: #fff;
}
.made-with-love i {
  font-style: normal;
  color: #f50057;
  font-size: 14px;
  position: relative;
  top: 2px;
}
.made-with-love a {
  color: #fff;
  text-decoration: none;
}
.made-with-love a:hover {
  text-decoration: underline;
}

/* for custom scrollbar for webkit browser*/

::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.react-confirm-alert-overlay {
  background: rgb(13 13 13 / 90%);
}

.react-confirm-alert-body > h1 {
  color: black;
}

.react-confirm-alert-button-group > button {
}

/* stokc search container  */
.stock-search-container {
  white-space: nowrap;
  align-items: center;
  background: linear-gradient(90deg, #555ea4, #555ea4);
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: -3px;
}

@media all and (max-width: 768px) {
  .stock-search-container {
    flex-direction: column;
  }
  .product-Video {
    width: 92.8%;
    display: flex;
    align-items: center;
    padding: 7px;
    justify-content: center;
    margin: 10px auto;
    border-radius: 3px;
    font-size: 15px;
  }
}
