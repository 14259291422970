.AddProduct-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    margin: 13px auto;
    border: 1px solid #cccccc59;
    border-radius: 10px;
}
.AddProduct-Video {
    background-color: #f3fdfa;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 7px auto;
    padding: 7px;
    border: 2px solid #ccc;
    border-radius: 5px;
}
.rdw-dropdownoption-default {
    color: #000;
}
.rdw-dropdown-selectedtext {
    color: #000;
}
.rdw-link-modal-label {
    color: #000;
}
.rdw-justify-aligned-block {
    color: #000;
}
.public-DraftStyleDefault-ltr {
    color: #000;
}
.rdw-image-modal-header {
    color: #000;
}
.rdw-image-modal-upload-option {
    overflow: hidden;
}

.AddProduct-subcontainer {
    display: flex;
    flex-direction: column;
    width: 70%;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
        rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
        rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 30px;
    text-align: center;
    color: #fff;
    background: linear-gradient(90deg, #555ea4, #555ea4);
    border-radius: 4px;
}
.AddProduct-subcontainer h3 {
    margin-bottom: 20px;
}

.AddProduct-submit-button {
    display: inline-block;
    outline: 0;
    cursor: pointer;
    padding: 9px 1px;
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
    vertical-align: middle;
    border: 1px solid;
    border-radius: 6px;
    color: #ffffff;
    background-color: #2ea44f;
    border-color: #1b1f2326;
    box-shadow: rgba(27, 31, 35, 0.04) 0px 1px 0px 0px,
        rgba(255, 255, 255, 0.25) 0px 1px 0px 0px inset;
    transition: 0.2s cubic-bezier(0.3, 0, 0.5, 1);
    transition-property: color, background-color, border-color;
    margin-top: 15px;
}
.AddProduct-submit-button:hover {
    background-color: #2c974b;
    border-color: #1b1f2326;
    transition-duration: 0.1s;
}
.form__label {
    font-family: 'Roboto', sans-serif;
    font-size: 1.2rem;
    margin-left: 2rem;
    margin-top: 0.7rem;
    display: block;
    transition: all 0.3s;
    transform: translateY(0rem);
}

.form__input_add {
    font-family: 'Roboto', sans-serif;
    color: #333;
    background-color: rgb(255, 255, 255);
    border: none;
    display: block;
    transition: all 0.3s;
    margin: 10px;
    padding: 15px;
}

.form__input_add:placeholder-shown + .form__label {
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translateY(-4rem);
    transform: translateY(-4rem);
}

.AddProduct-variation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.AddProduct-variation-input {
    width: 61%;
    padding: 12px;
    border-radius: 4px;
    border: none;
    margin-bottom: 8px;
}
.AddProduct-variation-button {
    width: 30%;
    padding: 9px;
    border-radius: 4px;
    border: none;
    margin-bottom: 8px;
    margin-left: 3px;
    background-color: green;
    color: #fff;
    font-size: 19px;
}
.AddProduct-display-variation {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    gap: 10px;
}
.AddProduct-display-variation-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 2px dotted;
    overflow: hidden;
    transition: 0.7s;
    gap: 10px;
}
.AddProduct-variation-input-price,
.AddProduct-variation-input-compare,
.AddProduct-variation-input-buy,
.AddProduct-variation-input-quantity,
.AddProduct-variation-input-ship,
.AddProduct-variation-input-time {
    width: 100%;
    padding: 4px;
    transition: 0.7s;
    font-size: 11px;
    color: #000;
}
.AddProduct-variation-file {
    width: 60px;
}
.AddProduct-variation-item-title {
    font-size: 12px;
    background-color: green;
    padding: 4px;
    border-radius: 13px;
    transition: 0.7s;
    width: 11%;
}
.AddProduct-file-var {
    width: 150px;
    margin: 10px;
}
.AddProduct-variation-remove {
    width: 5%;
    height: 5%;
    background-color: #f00;
    border-color: #f00;
}

.AddProduct-ColorSizeMain {
    border: 2px dotted #ccc;
    position: relative;
    padding: 10px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.AddProduct-remove-color-size {
    position: absolute;
    top: 6px;
    right: 7px;
    background-color: #f00;
    border-color: #f00;
    height: 20px;
    width: 20px;
}
.AddProduct-SizeName-input {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.AddProduct-color-Size-Title {
    background-color: #2c974b;
    margin: 5px;
    width: 18%;
    padding: 4px;
}
.AddProduct-color-size-name {
    width: 9%;
    text-align: left;
}
.AddProduct-size-input {
    width: 28%;
}
.AddProduct-file-ColorSize {
    width: 15%;
}
.AddProduct-file {
    padding-left: 85px;
    padding-top: 10px;
}
.AddProduct-category {
    padding: 15px;
    margin: 10px;
}
.AddProduct-upload-image {
    display: flex;
    align-items: center;
    justify-content: center;
}

.error-field {
    border: 2px solid red;
    background-color: #ffe6e6;
}

/* Style for error message text */
.error-text {
    color: red;
    font-size: 12px;
    margin-top: 5px;
}

@media screen and (max-width: 600px) {
    .AddProduct-container {
        width: 100%;
        border-radius: 0px;
        margin: 0px;
    }
    .AddProduct-subcontainer {
        width: 100%;
        padding: 0px;
        margin: 0px;
        box-shadow: none;
        border-radius: 0px;
    }
    .AddProduct-submit-button {
        width: 90%;
        margin: 17px auto;
        border-radius: 5px;
    }
    .AddProduct-Video {
        width: 90%;
    }
    .AddProduct-variation {
        gap: 5px;
    }
    .AddProduct-variation-input {
        width: 50%;
        margin: 0px;
        padding: 11px;
    }
    .AddProduct-variation-button {
        width: 50%;
        margin: 0px;
        font-size: 17px;
    }
    .AddProduct-display-variation {
        gap: 10px;
    }
    .AddProduct-display-variation-item {
        flex-direction: column;
        padding: 16px;
        width: 90%;
    }
    .AddProduct-variation-file {
        width: 50%;
        border: 1px solid #cccccc6b;
        padding: 8px;
    }
    .AddProduct-variation-remove {
        width: 30px;
        height: 30px;
        color: #fff;
        border-radius: 50%;
    }
    .AddProduct-variation-item-title {
        width: 50%;
    }
    .AddProduct-color-Size-Title {
        width: 96%;
        padding: 2px;
    }
}
