.CartMain{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    width: 80px;
    height: 92px;
    position: fixed;
    top: 20%;
    right: 0%;
    background-color: #70c332;
    z-index: 9999999;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid #4a9d0c;
    color: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    cursor: pointer;
}

.cart-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 14px;
}

.taka{
    background: #fff;
    width: 100%;
    text-align: center;
    color: #000;
    padding: 3px;
}