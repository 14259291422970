.profile-page-container {
  background-color: white;
  width: 100%;
  min-height: 100vh;
  padding: 10px 0;
}

.profile-container {
  width: 500px;
  background-color: white;
  margin: 50px auto;
  border-radius: 20px;
  box-shadow: 0 8px 60px 0 rgb(103 151 255 / 11%),
    0 12px 90px 0 rgb(103 151 255 / 11%);
}

.profile-header {
  font-size: 32px;
  color: white;
  font-family: "Kalam", cursive;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  background-color: #207fc7;
  padding: 20px;
  border-radius: 20px 20px 0px 0px;
}

.profile-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 30px;
}

.profile-image {
  height: 96px;
  width: 96px;
  margin: 0 auto;
  border-radius: 50%;
  align-self: center;
}

.profile-form-item {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.profile-form input {
  display: block;
  border: none;
  border-radius: 5px;
  background: rgba(109, 124, 144, 0.1);
  padding: 8px 20px;
  text-align: left;
  border: 2px solid transparent;
  line-height: 1.8em;
  letter-spacing: 0.5px;
  font-weight: 700;
  color: #20292f;
  outline: none;
}

.profile-hidden-input {
  display: none !important;
}

.profile-form-update-btn {
  all: unset;
  cursor: pointer;
  padding: 12px;
  text-align: center;
  background-color: rgb(72, 72, 198);
  color: white;
  border-radius: 10px;
}

.profile-password-changed {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}

.profile-link {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
  font-weight: 500;
}
.SMS-Balance {
  display: flex;
  padding: 20px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.SMS-Balance input {
  display: block;
  border: none;
  border-radius: 5px;
  background: rgba(109, 124, 144, 0.1);
  padding: 8px 20px;
  text-align: left;
  border: 2px solid transparent;
  line-height: 1.8em;
  letter-spacing: 0.5px;
  font-weight: 700;
  color: #20292f;
  outline: none;
  margin-bottom: 20px;
}
.buysms {
  width: 100%;
  border: 0;
  outline: 0;
  border-radius: 9999px;
  padding: 10px 0;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  background: linear-gradient(120deg, rgba(103, 151, 255, 0) 0%, #1b5ce4 100%);
  background-color: rgba(0, 0, 0, 0);
  background-color: #67b5ff;
  cursor: pointer;
}
@media all and (max-width: 768px) {
  .profile-container {
    width: 95%;
  }
}
