.modalBackgroundB {
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: first baseline;
  }
  
  .modalContainerB {
    width: 37%;
height: auto;
border-radius: 12px;
background-color: white;
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
display: flex;
flex-direction: column;
padding: 20px;
border: 2px solid cadetblue;
margin-top: 20px;
  }

  .modalContainerB .title {
    display: inline-block;
    text-align: center;
    margin-top: 10px;
  }

  .title h1{
    color: #000;
  }
  .titleCloseBtnB {
    display: flex;
    justify-content: flex-end;
  }
  
  .titleCloseBtnB button {
    background-color: transparent;
    border: none;
    font-size: 25px;
    cursor: pointer;
  }
  
  .modalContainerB .body {
    flex: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.7rem;
    text-align: center;
  }
  
  .modalContainerB .footerB {
    flex: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .modalContainerB .footerB button {
    width: 150px;
    height: 45px;
    margin: 10px;
    border: none;
    background-color: cornflowerblue;
    color: white;
    border-radius: 8px;
    font-size: 20px;
    cursor: pointer;
  }
  
  #cancelBtnB {
    background-color: crimson;
  }
  @media screen and (max-width: 768px) {
    .modalContainerB {
      width: 85%;
      
    }
  }