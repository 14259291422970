.pbutton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
.pbutton button {
  background-color: #2b05e26b;
  padding: 10px;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  margin: 33px;
}
.print-main {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 14px;
}

.print-inside {
  height: 200px;
  width: 300px;
  border: 1px solid;
  padding: 1px;
}

.print-inside p {
  font-size: 13px;
  line-height: 14px;
  margin: 3px;
  padding-left: 15px;
}

.print-inside h2 {
  font-size: 14px;
  margin: 0;
  text-align: center;
  padding-top: 6px;
}
