.expenes-main {
  height: auto;
  width: 96%;
  margin: 7px auto;
}

.Expense-Video {
  background-color: #d9e7e3;
  width: 98%;
  display: flex;
  align-items: center;
  padding: 9px;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 0px;
  margin-bottom: 7px;
  border-radius: 3px;
  font-size: 17px;
  border: 2px solid #ccc;
}
.Expense-Amount {
  background: #d4dada87;
  padding: 8px;
  border: 1px solid #cec4ff;
  color: #390000;
  border-radius: 7px;
}
.create-expenes {
  align-items: center;
  background: linear-gradient(90deg, #555ea4, #555ea4);
  display: flex;
  justify-content: space-around;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: -3px;
}

@media (max-width: 880px) {
  .expenes-main {
    width: 96%;
  }
  .create-expenes {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 3px;
  }
  .Expense-Video {
    width: 93%;
    font-size: 14px;
  }
}
